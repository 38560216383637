'use client';

import Image, { StaticImageData } from 'next/image';
import React, { Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { m } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import DropDownIcon from './ic-dropdown.png';
import background from './bg-dropdown-body.png';
import footerBackground from './bg-dropdown-footer.png';
import footerSlideUpBackground from './dropdown-up-top.png';
import slideBodyBackground from './dropdown-up-body.png';
import freeIcon from '@/components/exam/ExamHomeView/assets/ic_free.png';

import Button from '../Button';

interface DropDownItemProps {
  item: Item;
  onClick: (item: Item) => void;
  dropdownTextItemClassName?: string;
}
interface DropDownProps {
  items?: Item[];
  title?: string;
  wrapperClassName?: string;
  onChange?: (item: Item) => void;
  Icon?: StaticImageData;
  classNameButton?: string;
  classNameIcon?: string;
  classNameWrapIconText?: string;
  classNameWrapText?: string;
  activator?: ReactNode;
  direction?: keyof typeof directionValues;
  dropdownClassName?: string;
  dropdownTextItemClassName?: string;
  textClassName?: string;
  iconPolygonClassName?: string;
  isFree?: boolean;
}

const directionValues = {
  down: 'top-[-10px]',
  up: 'lg:top-[-28px] sm:top-[-12px] top-[-25px]',
};

export interface Item {
  title: string;
  slug?: string;
  key?: string;
  id: string;
  url?: string;
  blankUrl?: boolean;
  isActive?: boolean;
}

function DropdownItem({ item, onClick, dropdownTextItemClassName }: DropDownItemProps) {
  const handleClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return (
    <div className="px-2 py-1">
      <Menu.Item>
        <Button
          size="small"
          colorScheme="indigo"
          classNameText={twMerge(`2xl:text-[12px] text-[9px] uppercase`)}
          className={dropdownTextItemClassName}
          animation={false}
          onClick={handleClick}
          isActive={item.isActive}
        >
          {item.title}
        </Button>
      </Menu.Item>
    </div>
  );
}

function DropDown({
  Icon,
  title,
  items = [],
  classNameButton,
  classNameIcon,
  classNameWrapText,
  classNameWrapIconText,
  wrapperClassName,
  activator,
  direction = 'down',
  dropdownClassName,
  dropdownTextItemClassName,
  onChange,
  textClassName = 'text-[9px] md:text-[7px] lg:text-[8px] xl:text-[11px] sm:text-[5px] 2xl:text-[14px]',
  iconPolygonClassName = 'w-[10px] xl:w-[10px] 2xl:w-[15px] sm:w-[5px]',
  isFree,
}: DropDownProps) {
  const router = useRouter();
  const handleClick = useCallback(
    (item: Item) => {
      if (item.url) {
        if (item.blankUrl) {
          window.open(item.url);
          return null;
        }
        return router.push(item.url);
      }
      if (onChange) {
        onChange(item);
      }
      return null;
    },
    [onChange, router],
  );

  const [isUp, setIsUp] = useState(false);

  useEffect(() => {
    if (direction === 'up') {
      setIsUp(true);
    }
  }, [direction]);

  return (
    <Menu as="div" className={twMerge('relative inline-block w-fit', wrapperClassName)}>
      <Menu.Button as="div">
        {({ open: isActive }) => {
          return activator ? (
            <div className="[&>*]:z-[3]">{activator}</div>
          ) : (
            <Button
              shape="square"
              className={twMerge(
                'z-[3] rounded-[16px] lg:rounded-[16px] sm:rounded-[12px] uppercase',
                classNameButton,
              )}
              variant="polish"
              colorScheme="indigo"
              animation={false}
              isActive={isActive}
            >
              {isFree && (
                <Image
                  src={freeIcon}
                  alt="free"
                  className="absolute z-20 -top-[5%] -left-[10%] w-[60%] h-[60%] object-contain"
                />
              )}
              <div className={twMerge('wrap-content item flex flex-col', classNameWrapIconText)}>
                <div className="m-auto lg:w-full flex justify-center">
                  {Icon && (
                    <Image
                      src={Icon}
                      alt="flyer"
                      className={twMerge('w-[80%] h-[100%]', classNameIcon)}
                    />
                  )}
                </div>
                <div
                  className={twMerge(
                    'relative text-[9px] flex items-center justify-center',
                    classNameWrapText,
                  )}
                >
                  <span className={textClassName}>{title}</span>
                  <m.span
                    initial="open"
                    animate={isActive ? 'closed' : 'open'}
                    variants={{
                      open: {
                        rotate: '0deg',
                        transition: {
                          duration: 0.5,
                        },
                      },
                      closed: {
                        rotate: '180deg',
                        transition: {
                          duration: 0.5,
                        },
                      },
                    }}
                    className={twMerge('inline-block ml-1', iconPolygonClassName)}
                  >
                    <Image src={DropDownIcon} alt="polygon" />
                  </m.span>
                </div>
              </div>
            </Button>
          );
        }}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={twMerge(
            'absolute origin-top-right right-0',
            'w-full rounded-md focus:outline-none z-[2]',
            isUp && 'bottom-full',
            dropdownClassName,
          )}
        >
          <div>
            {items.map((item) => {
              return (
                <DropdownItem
                  dropdownTextItemClassName={dropdownTextItemClassName}
                  item={item}
                  onClick={handleClick}
                  key={item.title}
                />
              );
            })}
          </div>
          <div
            className={twMerge(
              'absolute',
              directionValues[direction],
              'left-0 w-full h-full z-[-1]',
            )}
          >
            <div className="relative h-full w-full">
              {isUp && <Image src={footerSlideUpBackground} alt="" className="w-full mb-[-1px]" />}
              <Image
                src={isUp ? slideBodyBackground : background}
                alt=""
                className="h-full w-full"
              />
              {!isUp && <Image src={footerBackground} alt="" className="w-full" />}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default DropDown;
